@charset "UTF-8";
//1. Configuration and helpers
@import 'abstracts/variables';
@import 'abstracts/mixin';

//2. Vendors
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/helpers';
@import '~bootstrap/scss/utilities/api';
@import '~sass-mq/_mq.scss';

//3. Base stuff
//@import 'base/reset';
@import 'base/color';
@import 'base/utility';
@import 'base/form';
@import 'base/functions';
@import 'base/text';

//4. components
@import 'components/button';
@import 'components/input';
@import 'components/modal';

//5. Page-specific styles
@import 'pages/register';
@import 'pages/landing';
@import 'pages/policyAndTerm';
//6. Layout-related sections
@import 'layout/body';

.notification-link {
  a {
    color: #006597;
    text-decoration: underline;
  }
}
