// btn-primary
.btn-default {
  // padding: 25px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  text-align: center;
  align-self: center;
  align-items: center;
  height: 60px;
  border-radius: 60px;

  &:hover {
    opacity: 0.8;
  }
}

.btn-black {
  background-color: $color-black;
  color: $color-white;
  border-color: $color-black;

  &:hover {
    background-color: $color-black;
    color: $color-white;
    border-color: $color-black;
  }
}

.btn-chestnut-rose {
  background-color: var(--color-chestnut-rose);
  color: var(--color-white);
  border-color: var(--color-chestnut-rose);

  &:hover {
    background-color: var(--color-chestnut-rose);
    color: var(--color-white);
    border-color: var(--color-chestnut-rose);
  }
}

.btn-gurkha {
  background-color: var(--color-gurkha);
  color: var(--color-white);
  border-color: var(--color-gurkha);

  &:hover {
    background-color: var(--color-gurkha);
    color: var(--color-white);
    border-color: var(--color-gurkha);
  }
}

.btn-border-black {
  background-color: var(--color-white);
  color: var(--color-black);
  border-color: var(--color-black);

  &:hover {
    background-color: var(--color-white);
    color: var(--color-black);
    border-color: var(--color-black);
  }
}

.btn-white {
  background-color: $color-white;
  color: $color-black;
  border-color: $color-black;

  &:hover,
  &:focus {
    background-color: $color-white;
    color: $color-black;
    border-color: $color-black;
    opacity: 0.5;
  }
}

.btn-inactive {
  background-color: $color-inactive;
  color: $color-white;
  border-color: $color-black;

  &:hover {
    background-color: $color-inactive;
    color: $color-white;
    border-color: $color-black;
  }
}
.btn-gurkha {
  background-color: var(--color-gurkha);
  color: var(--color-white);
  border-color: var(--color-gurkha);

  &:hover {
    background-color: var(--color-gurkha);
    color: var(--color-white);
    border-color: var(--color-gurkha);
  }
}

.btn-postal {
  padding: 9px 25px;
  height: 43px !important;
  border-radius: 20px;
  font-size: 15px !important;
}
.modal-confirm-btn {
  .ant-modal-footer {
    padding: 0;
    border-top: 1px solid $color-inactive;
    border-radius: 2px;
    justify-content: center;
    display: flex;
  }
}

.btn-modal {
  width: 45%;
  height: 100%;
  padding: 12px;
}

.btn-warning {
  background: #998800;
  color: #ffffff;
  border: unset;
}

// SHEEP MEDICAL
.btn {
  &-primary {
    background-color: $color-primary;
    border: 0;
    color: $color-white;
  }
}
