.aga-btn {
  &.ant-btn-lg {
    height: 60px;
  }
}

.page-title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0.13em;
  color: #000000;
}

.ant-btn-primary:focus {
  color: var(--color-white);
  border-color: var(--color-chestnut-rose);
  background: var(--color-chestnut-rose);
  opacity: 0.8;
}
