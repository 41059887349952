//Utility
//------------------------------------------------------------------------
//------------------------------------| cursor |------------------------------------
.c-pointer {
  cursor: pointer !important;
}

.c-pointer-all * {
  cursor: pointer !important;
}

.cursor-none {
  cursor: auto !important;
}

//------------------------------------| auto box |------------------------------------
.mg-auto {
  margin: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mgc {
  margin: 0 auto;
}

//------------------------------------| top |------------------------------------
.top--4x {
  top: -4px !important;
}

.top-81 {
  top: 81px !important;
}

.top-101 {
  top: 101px !important;
}

//------------------------------------| border |------------------------------------
.border {
  border: 1px solid $color-black !important;
}

.border-bottom-lucky {
  border-bottom: 1px solid $color-lucky;
}

.border-top-lucky {
  border-top: 1px solid $color-lucky;
}

.border-3 {
  border: 3px solid !important;
}

.border-4 {
  border: 4px solid !important;
}

//one
.border-top-2 {
  border-top: 2px solid !important;
}

.border-right-2 {
  border-right: 2px solid !important;
}

.border-left-2 {
  border-left: 2px solid !important;
}

.border-bottom-2 {
  border-bottom: 2px solid !important;
}

.border-bottom-gray {
  border-bottom: 1px solid #cccccc !important;
}

.border-bottom-warning {
  border-bottom: 3px solid #998800 !important;
}

//color
.border-black {
  border-color: $color-black !important;
}

.border-white {
  border-color: $color-white !important;
}

.border-bottom-ccc {
  border-bottom: 1px solid $color-inactive !important;
}

.border-left {
  border-left: 1px solid $color-inactive !important;
}

.border-right {
  border-right: 1px solid $color-inactive !important;
}

//------------------------------------| rounded |------------------------------------
.rounded-5 {
  border-radius: 5px !important;
}

.rounded-6 {
  border-radius: 6px !important;
}

//double
.rounded-top-0 {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-top-5 {
  border-radius: 5px 5px 0 0 !important;
}

.rounded-top-6 {
  border-radius: 6px 6px 0 0 !important;
}

.rounded-bottom-5 {
  border-radius: 0 0 5px 5px !important;
}

.rounded-bottom-6 {
  border-radius: 0 0 6px 6px !important;
}

//one
.rounded-top-right-6 {
  border-top-right-radius: 6px !important;
}

.rounded-bottom-left-6 {
  border-bottom-left-radius: 6px !important;
}

//extra
.border-none {
  border: none;
}

//------------------------------------| height |------------------------------------
.hei-x105 {
  height: 105px !important;
}

.hei-x84 {
  height: 84px !important;
}

.hei-x200 {
  height: 200px !important;
}

.hei-x45 {
  height: 45px !important;
}

.hei-vh01 {
  min-height: calc(100vh - 146px);
}

.hei-x75 {
  height: 75px !important;
}

.mih-x120 {
  min-height: 120px !important;
}

.mih-x300 {
  min-height: 300px !important;
}

//------------------------------------| width |------------------------------------
.w-95-5 {
  width: 95.5% !important;
}

.wid-x200 {
  width: 200px !important;
}

.wid-x88 {
  width: 88px !important;
}

.wid-x65 {
  width: 65px !important;
}

.wid-x50 {
  width: 50px !important;
}

.wid-x1240 {
  width: 1240px !important;
}

.miw-x20 {
  min-width: 23px !important;
}

.miw-92 {
  min-width: 92% !important;
}

.mwid-x340 {
  max-width: 340px !important;
}

.mwid-x100 {
  max-width: 100px !important;
}

.mwid-x21 {
  max-width: 21px !important;
}

//------------------------------------| flex |------------------------------------
.flex-cell {
  flex: 0 0 auto;
}

.flex-full {
  flex: 1;
}

.flex-50p {
  flex: 1 0 50%;
}

.flex-0050 {
  flex: 0 0 50%;
}

.flex-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.flex-between {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.flex-around {
  display: flex !important;
  justify-content: space-around !important;
  align-items: center !important;
}

.flex-start {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}

.flex-end {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
}

//------------------------------------| overflow |------------------------------------
.overflow-hd {
  overflow: hidden;
}

.oflowy-hd {
  overflow-y: hidden;
}

.oflowx-hd {
  overflow-x: hidden;
}

.oflow-auto {
  overflow: auto;
}

//------------------------------------| padding && margin |------------------------------------
.p-x13y6 {
  padding: 13px 6px;
}

.p-x4y15 {
  padding: 4px 15px !important;
}

.p-x4y10 {
  padding: 4px 10px !important;
}

.mg-x30y20 {
  margin: 30px 20px;
}

.mg-x60y20 {
  margin: 20px 60px 30px;
}

$sizes: 3, 5, 10, 20, 15, 20, 25, 30, 35, 40, 50, 60, 70, 100;

@each $size in $sizes {
  .mt-#{$size} {
    margin-top: $size + px;
  }
  .mr-#{$size} {
    margin-right: $size + px;
  }
  .ml-#{$size} {
    margin-left: $size + px;
  }
  .mb-#{$size} {
    margin-bottom: $size + px !important;
  }
  .pt-#{$size} {
    padding-top: $size + px;
  }
  .pr-#{$size} {
    padding-right: $size + px;
  }
  .pl-#{$size} {
    padding-left: $size + px;
  }
  .pb-#{$size} {
    padding-bottom: $size + px !important;
  }
  .m-#{$size} {
    padding: $size + px;
  }
  .p-#{$size} {
    padding: $size + px !important;
  }
}

$fws: 100, 200, 300, 400, 500, 600, 700, 800, 900;
@each $fw in $fws {
  .fw-#{$fw} {
    font-weight: $fw !important;
  }
}

//------------------------------------| text |------------------------------------
.text-decoration-none {
  text-decoration: none;
}

.text-decoration-underline {
  text-decoration: underline;
  text-underline-position: under;
}

.text-id14 {
  text-indent: -14px;
  margin-left: 14px;
}

.text-id12 {
  text-indent: -12px;
  margin-left: 12px;
}

.text-initial {
  white-space: initial;
}

.text-align-last-center {
  text-align-last: center !important;
}

.text-stiletto {
  color: $color-stiletto;
}

//------------------------------------| Helper |------------------------------------
.link-none {
  pointer-events: none;
}

.shadow-md {
  box-shadow: 0 3px 8px $silver-chalice !important;
}

.shadow-md-dark {
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 20%), 0 6px 20px 0 rgba(0, 0, 0, 19%) !important;
}

.shadow-lg {
  box-shadow: 0 3px 4px rgba(0, 0, 0, 50%) !important;
}

.lh-80 {
  line-height: 80px !important;
}

.lh-initial {
  line-height: initial;
}

.box-content {
  box-sizing: content-box;
}

.input-numeric {
  appearance: textfield;
}

.input-numeric::-webkit-inner-spin-button,
.input-numeric::-webkit-outer-spin-button {
  -webkit-appearance: textfield;
  margin: 0;
}

.customize-li {
  list-style: disc inside none;
}

.letter-spacing-06 {
  letter-spacing: -0.6px !important;
}

.z-index3 {
  z-index: 3 !important;
  position: relative;
}

.z-index4 {
  z-index: 4 !important;
  position: relative;
}

.z-index13 {
  z-index: 13;
}

.ml--1 {
  margin-left: -1px;
}

.mr--1 {
  margin-right: -1px;
}

.ml--8 {
  margin-left: -8px;
}

.ml--124x {
  margin-left: -124px;
}

.textOverflow-235x {
  @include truncate(235);
}

.textOverflow-194x {
  @include truncate(194);
}

.order--1 {
  order: -1;
}

.cus-scroll {
  /* Let's get this party started */
  ::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $color-black-03;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: $color-silver;
    -webkit-box-shadow: inset 0 0 6px $color-black-05;
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    background: $color-silver;
  }
}

.mt-x200 {
  margin-top: 100px;
}

.work-bread-all {
  word-break: break-all;
}

.nowrap {
  white-space: nowrap;
}

.wrap-text {
  word-wrap: break-word;
}

.box-shadow-none {
  box-shadow: none;
}

.text-wrap {
  white-space: normal !important;
}

.mb---10 {
  margin-bottom: -10px !important;
}

.pb--110 {
  padding-bottom: 110px !important;
}

//Page desktop (min-width: 768px)
//------------------------------------------------------------------------
@media screen and (min-width: 768px) {
  .border-md {
    border: 1px solid;
  }

  .border-gray-dd-md {
    border: 1px solid $color-alto !important;
  }

  .border-md-none {
    border: none !important;
  }

  .rounded-md-0 {
    border-radius: 0 !important;
  }

  .position-md-static {
    position: static !important;
  }

  .mgc-md {
    margin: 0 auto !important;
  }

  .flex-center-md {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .flex-md-center {
    display: flex;
    justify-content: center;
    align-items: baseline;
  }

  .flex-md-between {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }

  .flex-md-left {
    display: flex !important;
    justify-content: left !important;
    align-items: center !important;
  }

  .hei-md-x16 {
    height: 16px !important;
  }

  .hei-md-x42 {
    height: 42px !important;
  }

  .order-md--1 {
    order: -1;
  }

  .px-md--10 {
    margin: 0 -10px !important;
  }

  .mt-x200 {
    margin-top: 200px;
  }

  .ml-x50 {
    margin-left: 0 !important;
  }

  .fw-md-bold {
    font-weight: bold !important;
  }

  .text-md-center {
    text-align: center;
  }

  .w-md-40 {
    width: 40% !important;
  }
}

.hei-x90 {
  height: 90px;
}

.un-choose {
  background-color: $color-alto !important;
  pointer-events: none;
}

.bg-white-no-choose {
  background-color: $color-white !important;
}

.bg-white {
  background-color: $color-white !important;
}

.un-choose-event {
  pointer-events: none;
}

.fs-35 {
  font-size: 35px;
  font-family: 'Trebuchet MS', sans-serif;
  margin-bottom: -3px;
}

.fs-sm-35 {
  @media (max-width: 768px) {
    font-size: 30px;
  }
}

.lh-09 {
  line-height: 0.9;
}

.hei-x135 {
  height: 135px;
}

.text-warning {
  color: var(--color-warning) !important;
}

.top-70 {
  top: 70px !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.not-padding-p {
  p {
    margin-bottom: 0;
  }
}

input::placeholder {
  font-size: 13px;
}

.pre-wrap {
  white-space: pre-wrap;
}

#rswp-card-button {
  font-weight: bold;
  border-radius: unset;
  font-size: 18px;
}

.position-relative {
  position: relative;
}
