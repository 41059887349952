//
//mixin
//------------------------------------------------------------------------
//center
@mixin center-flex($display: flex, $center: center) {
  display: $display;
  justify-content: $center;
  align-items: $center;
}

//Contain floats / clearfix
@mixin cf {
  &:after {
    content: '';
    display: inline-table;
    clear: both;
  }
}

//Max text
@mixin truncate($truncation-boundary) {
  max-width: #{$truncation-boundary}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//Hide from both screenreaders and browsers
@mixin hidden {
  display: none;
  visibility: hidden;
}

@mixin visible($state: 'block') {
  display: unquote($state);
  visibility: visible;
}

//Hide only visually, but have it available for screenreaders
@mixin vh($focusable: false) {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  @if $focusable {
    @include vh-focusable;
  }
}

@mixin vh-reset {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

//Allow the element to be focusable when navigated to via the keyboard
@mixin vh-focusable {
  &:active,
  &:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}

//Hide visually and from screenreaders, but maintain layout
@mixin invisible {
  visibility: hidden;
}

@mixin border-radius-item(
  $radiusTopLeft: 0,
  $radiusTopRight: 0,
  $radiusBottomLeft: 0,
  $radiusBottomRight: 0
) {
  border-top-left-radius: #{$radiusTopLeft}px;
  border-top-right-radius: #{$radiusTopRight}px;
  border-bottom-left-radius: #{$radiusBottomLeft}px;
  border-bottom-right-radius: #{$radiusBottomRight}px;
}

//table
@mixin table-radius(
  $border,
  $color,
  $radiusTopLeft: 0,
  $radiusTopRight: 0,
  $radiusBottomLeft: 0,
  $radiusBottomRight: 0
) {
  @include border-radius-item(
    #{$radiusTopLeft},
    #{$radiusTopRight},
    #{$radiusBottomLeft},
    #{$radiusBottomRight}
  );

  border-bottom: #{$border}px solid #{$color};
  border-collapse: separate;
}
