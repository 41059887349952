body {
  background-color: #fff;
  color: #000;
  font-family: 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN',
    'Hiragino Sans', Meiryo, sans-serif;
}

.layout-webview {
  padding-left: 20px;
  padding-right: 20px;
  &-step {
    padding: 30px;
    padding-top: 64px;
  }
}

.stop-scroll {
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  /* Other browsers */
  overscroll-behavior: none;
}

/* fadeIn */
@keyframes imageFadeIn {
  from {
    opacity: 0;
    transform: scale(0.9, 0.9);
  }
  to {
    opacity: 1;
    transform: scale(1, 1);
  }
}
