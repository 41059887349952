.basic-modal {
  .ant-modal-content {
    border-radius: 0.75rem;
  }
  .ant-modal-body {
    padding: 20px 0;
    background: var(--color-spring-wood);
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }
  .modal-content {
    width: 100% !important;
    padding: 20px 40px !important;
  }
}
.modal-menu {
  .ant-drawer-body {
    background: var(--color-limed-ash);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .menu {
    margin-top: 50px;
    .menu-item {
      border-bottom: 1px solid var(--color-lemon-grass);
      padding: 20px 0;
    }
    .menu-name {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      display: flex;
      align-items: center;
      letter-spacing: 0.13em;
    }
  }
  .logout {
    display: flex;
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      display: flex;
      align-items: center;
      letter-spacing: 0.13em;
      color: var(--color-white);
      margin-left: 10px;
      margin-bottom: 20px;
    }
  }
  .ant-drawer-content-wrapper {
    width: 300px !important;
  }
}
