//
// This file contains CSS helper classes.
//------------------------------------------------------------------------

//------------------------------------| Width |------------------------------------
// (ex: w-5 { width: 5% !important; })
// .w-0, .w-1 >> .w-100
@for $value from 0 through 100 {
  .w-#{$value + 0} {
    width: $value + 0% !important;
  }
}

//------------------------------------| Width |------------------------------------
// (ex: w-5 { width: 5% !important; })
// .w-0, .w-1 >> .w-100
@for $value from 0 through 100 {
  .mw-#{$value + 0} {
    max-width: $value + 0% !important;
  }
}

// (ex: .wid-x20 { width: 20px !important; })
// .wid-x20, .wid-x21 >> .wid-x100
@for $value from 20 through 100 {
  .wid-x#{$value + 0} {
    width: $value + px !important;
  }
}

@include mq(tablet) {
  @for $value from 0 through 100 {
    .w-md-#{$value + 0} {
      width: $value + 0% !important;
    }
  }
}

//------------------------------------| Height |------------------------------------
// (ex: .hei-x10 { height: 10px !important; })
// .hei-x10, .hei-x11 >> .hei-x40
@for $value from 0 through 30 {
  .hei-x#{$value + 10} {
    height: $value + 10px !important;
  }
}
// .hei-x50, .hei-x60 >> .hei-x100
@for $value from 5 through 10 {
  .hei-x#{$value * 10} {
    height: $value * 10px !important;
  }
}

//------------------------------------| Margin & Padding All |------------------------------------
// Margin-$ & Padding-$ For all
// (ex: m--10 { margin: 10px !important; })
// .m--0, .m--1 >> .m--30
// .p--0, .p--1 >> .p--30
@for $value from 0 through 30 {
  .m--#{$value} {
    margin: $value + px !important;
  }
  .p--#{$value} {
    padding: $value + px !important;
  }
}

@include mq(tablet) {
  @for $value from 0 through 30 {
    .m-md--#{$value} {
      margin: $value + px !important;
    }
    .p-md--#{$value} {
      padding: $value + px !important;
    }
  }
}
// Margin-$ & Padding-$ For 2
// (ex: .mx--4 { margin-right: 4px !important; margin-left: 4px !important; })
// .mx--0, .mx--1 >> .mx--30
// .my--0, .mp--1 >> .my--30
// .px--0, .px--1 >> .px--30
// .py--0, .mp--1 >> .py--30
@for $value from 0 through 30 {
  .mx--#{$value} {
    margin-right: $value + px !important;
    margin-left: $value + px !important;
  }

  .my--#{$value} {
    margin-top: $value + px !important;
    margin-bottom: $value + px !important;
  }

  .px--#{$value} {
    padding-right: $value + px !important;
    padding-left: $value + px !important;
  }

  .py--#{$value} {
    padding-top: $value + px !important;
    padding-bottom: $value + px !important;
  }
}

@include mq(tablet) {
  @for $value from 0 through 30 {
    .mx-md--#{$value} {
      margin-right: $value + px !important;
      margin-left: $value + px !important;
    }

    .my-md--#{$value} {
      margin-top: $value + px !important;
      margin-bottom: $value + px !important;
    }

    .px-md--#{$value} {
      padding-right: $value + px !important;
      padding-left: $value + px !important;
    }

    .py-md--#{$value} {
      padding-top: $value + px !important;
      padding-bottom: $value + px !important;
    }
  }
}
// Margin-$ & Padding-$ For 1
// (ex: mt-10 { margin-top: 10px !important; })
// .mt-0, .mt-1 >> .mt-100
// .mr-0, .mr-1 >> .mr-100
// .mb-0, .mb-1 >> .mb-100
// .ml-0, .ml-1 >> .ml-100
// @for $value from 0 through 100 {
//   .mt-#{$value} { margin-top: $value + px !important; }
//   .mr-#{$value} { margin-right: $value + px !important; }
//   .mb-#{$value} { margin-bottom: $value + px !important; }
//   .ml-#{$value} { margin-left: $value + px !important; }

//   // Negative Margin-$
//   .nmt-#{$value} { margin-top: -$value + px !important; }
//   .nmr-#{$value} { margin-right: -$value + px !important; }
//   .nmb-#{$value} { margin-bottom: -$value + px !important; }
//   .nml-#{$value} { margin-left: -$value + px !important; }

//   .pt-#{$value} { padding-top: $value + px !important; }
//   .pr-#{$value} { padding-right: $value + px !important; }
//   .pb-#{$value} { padding-bottom: $value + px !important; }
//   .pl-#{$value} { padding-left: $value + px !important; }
// }

@include mq(tablet) {
  @for $value from 0 through 100 {
    .mt-md-#{$value} {
      margin-top: $value + px !important;
    }
    .mr-md-#{$value} {
      margin-right: $value + px !important;
    }
    .mb-md-#{$value} {
      margin-bottom: $value + px !important;
    }
    .ml-md-#{$value} {
      margin-left: $value + px !important;
    }

    .pt-md-#{$value} {
      padding-top: $value + px !important;
    }
    .pr-md-#{$value} {
      padding-right: $value + px !important;
    }
    .pb-md-#{$value} {
      padding-bottom: $value + px !important;
    }
    .pl-md-#{$value} {
      padding-left: $value + px !important;
    }
  }
}

//------------------------------------| font size |------------------------------------
// (ex: .fs-30 { font-size: 30px !important; })
// .fs-10, .fs-11 >> .fs-30
@for $value from 10 through 30 {
  .fs-#{$value} {
    font-size: $value + px !important;
  }
}

@include mq(tablet) {
  @for $value from 10 through 30 {
    .fs-md-#{$value} {
      font-size: $value + px !important;
    }
  }
}

@include mq($until: mobile-small) {
  @for $value from 9 through 25 {
    .fs-sm-#{$value} {
      font-size: $value + px !important;
    }
  }
}
//------------------------------------| line height |------------------------------------
// (ex: .lh-30 { line-height: 30px !important; })
// .lh-10, .lh-11 >> .lh-30
@for $value from 10 through 30 {
  .lh-#{$value} {
    line-height: $value + px !important;
  }
}

@include mq(tablet) {
  @for $value from 10 through 30 {
    .lh-md-#{$value} {
      line-height: $value + px !important;
    }
  }
}

// //------------------------------------| position |------------------------------------
// (ex: .top-1 { top: 1px !important; })
// .top-0, .top-1 >> .top-30
@for $value from 0 through 30 {
  .top-#{$value} {
    top: $value + px !important;
  }
  .right-#{$value} {
    right: $value + px !important;
  }
  .bottom-#{$value} {
    bottom: $value + px !important;
  }
  .left-#{$value} {
    left: $value + px !important;
  }
}

@include mq(tablet) {
  @for $value from 0 through 30 {
    .top-md-#{$value} {
      top: $value + px !important;
    }
    .right-md-#{$value} {
      right: $value + px !important;
    }
    .bottom-md-#{$value} {
      bottom: $value + px !important;
    }
    .left-md-#{$value} {
      left: $value + px !important;
    }
  }
}

// //------------------------------------| Order |------------------------------------
// (ex: .order-1 { order: 1 !important; })
// .order-0, .order-1 >> .order-12
@for $value from 0 through 12 {
  .order-#{$value} {
    order: $value !important;
  }
}

@include mq(tablet) {
  @for $value from 0 through 12 {
    .order-md-#{$value} {
      order: $value !important;
    }
  }
}

//------------------------------------| Border |------------------------------------
// (ex: w-5 { width: 5% !important; })
// .br-0, .br-1 >> .br-100
@for $value from 0 through 100 {
  .br-#{$value + 0} {
    border-radius: $value + 0px !important;
  }
}
