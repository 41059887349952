@media (min-width: 767px) {
  .landing .lp-header {
    height: 140px !important;
  }
  .landing .lp-header img {
    width: 40% !important;
  }
  .landing .step-header-content {
    margin-top: 140px !important;
  }
  .landing .landing__content-img {
    width: 100% !important;
  }
  .landing .landing__content-img-third {
    width: 50% !important;
  }
  .landing .loading-content {
    height: calc(100vh - 193px) !important;
  }
  .mt-70 .step-header-content {
    margin-top: 140px;
  }
}

@media (min-width: 821px) {
  .landing {
    // max-width: 410px !important;
    margin: auto;
    width: 100%;
    height: auto;
    // max-width: 428px;
    margin: 0 auto;
  }
  .landing .step-header-content {
    // max-width: 410px !important;
    margin: auto;
    width: 100%;
    height: auto;
    // max-width: 428px;
    margin: 0 auto;
    position: static !important;
    margin-top: 0 !important;
  }
  .mt-70 .step-header-content {
    margin-top: 140px;
  }
  .landing .lp-header {
    height: 70px !important;
  }
  .nickname-content {
    margin-top: 0 !important;
  }
  .loading-content {
    margin-top: 0 !important;
  }
}
