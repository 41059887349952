.policy {
  font-size: 13px;

  &-top {
    font-size: 18px;
    padding: 0 5px 10px;
    border-bottom: 2px solid #0e0d0a;
    letter-spacing: 2px;
    margin-bottom: 30px;
  }

  &-title {
    font-size: 16px;
    font-weight: bold;
    margin: 15px 0;
    letter-spacing: 2px;
  }

  &-header {
    font-weight: bold;
    font-size: 18px;
    margin: 10px 0;
    z-index: 1;
    letter-spacing: 2px;
  }

  &-hr {
    margin: 15px 0;
  }

  &-content {
    margin-bottom: 80px;
  }

  &-footer {
    position: fixed;
    padding: 20px;
    text-align: center;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    width: 100%;
    background-color: #f5f3e9;
    border-top: 2px solid #ccc;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
  }

  .reset-link {
    color: -webkit-link;
    text-decoration: underline;
  }
}
