//
//Color
//------------------------------------------------------------------------

//------------------------------------| text |------------------------------------
.cl-web {
  color: $color-black !important;
}

.cl-primary {
  color: $primary-color !important;
}

//white
.cl-white {
  color: $color-white !important;
}

//black
.cl-black {
  color: $color-black !important;
}

//black
.cl-stiletto {
  color: $color-stiletto !important;
}

//black
.cl-dusty-gray {
  color: $color-dusty-gray !important;
}

//red
.cl-warning {
  color: $color-warning !important;
}

//Crimson
.cl-crimson {
  color: $color-warning !important;
}

// color-chestnut-rose
.cl-chestnut-rose {
  color: $color-chestnut-rose !important;
}

.cl-bahama-blue {
  color: $color-bahama-blue !important;
}

//------------------------------------| border |------------------------------------
//------------------------------------| background |------------------------------------
.bg-yuma {
  background-color: var(--color-yuma);
}

.chat-link {
  color: var(--color-chestnut-rose) !important;
}
