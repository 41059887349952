//
// variables
:root {
  //color
  --color-mandys-pink: #f2bdac;
  --color-rangoon-green: #0e0d0a;
  --color-white: #fff;
  --color-black: #000;
  --color-limed-ash: #767964;
  --color-yuma: #cfc392;
  --color-quill-gray: #d0cfcb;
  --color-midnight: #001d45;
  --color-persian-green: #00ae93;
  --color-neon-carrot: #ff8c39;
  --color-chestnut-rose: #ce5848;
  --color-spring-wood: #f5f3e9;
  --color-stark-white: #ded5b3;
  --color-star-dust: #9f9f99;
  --color-spring-wood-2: #faf9f4;
  --color-desert-storm: #ebebea;
  --color-pampas: #f1efe8;
  --color-gurkha: #9e956f;
  --color-alto: #d9d9d9;
  --color-lemon-grass: #9fa193;
  --color-dusty-gray: #525542;
  --color-kelp: #525542;
  --color-warning: #ce5848;
  --color-bahama-blue: #006597;
}

//------------------------------------------------------------------------

// ---- font-family
$font-web: 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN', 'Hiragino Sans',
  Meiryo, sans-serif;

// ---- breakpoints
// See https://github.com/sass-mq/sass-mq/ for original full version.
$mq-breakpoints: (
  mobile-small: 321px,
  mobile: 375px,
  mobile-wide: 415px,
  tablet: 768px,
  desktop: 1280px,
);

// ---- font-size
$p: 14px;
$p-sm: 13px;
$p-small: 12px;
$p-medium: 18px;
$p-large: 24px;

// ---- line-height
$lh: 1.5;
$lh-x-small: 1.1;
$lh-small: 1.25;
$lh-large: 1.75;

// ---- font-weight
$w-light: 300;
$w-normal: 400;
$w-semibold: 600;
$w-bold: 700;
$w-heavy: 800;

// ---- base-url
$base-url: '../images' !default;

//------------------------------------| Color |------------------------------------
// https://css-tricks.com/what-do-you-name-color-variables/#comment-1653710
// http://chir.ag/projects/name-that-color

$color-white-057: rgba(255, 255, 255, 57%);
$color-white-073: rgba(255, 255, 255, 73%);
$color-white-0: rgba(255, 255, 255, 0);
$color-black-045: rgba(0, 0, 0, 45%);
$color-shark-075: rgba(49, 50, 51, 75%);
$color-black-03: rgba(0, 0, 0, 0.3);
$color-black-05: rgba(0, 0, 0, 5%);
$color-outer-space-02: rgba(49, 55, 61, 20%);
$color-alto-088: rgba(210, 210, 210, 0.88);
$color-selective-yellow-07: rgba(255, 186, 0, 70%);
$color-silver-0411: rgba(206, 203, 203, 0.411);
$color-cod-gray-088: rgba(29, 29, 29, 0.88);
$color-gray-light: rgba(211, 211, 211, 0.38);

$color-transparent: transparent;
$color-white: #fff;
$color-black: #000;
$color-outer-space: #31373d;
$color-regent-gray: #798c9c;
$color-mercury: #e8e8e8;
$color-mine-shaft: #303030;
$color-emperor: #4f4f4f;
$color-dove-gray: #646464;
$color-tundora: #4c4c4c;
$color-boulder: #757373;
$color-silver: #bdbdbd;
$color-gallery-gray: #ededed;
$color-picton-blue: #51b4e0;
$color-turquoise: #35cbc8;
$color-chambray: #415993;
$color-smalt: #004099;
$color-curious-blue: #3498db;
$color-lily-white: #eefaff;
$color-shakespeare: #3aa1cf;
$color-sail: #b2d9fa;
$color-white-ice: #edf7fc;
$color-denim: #1479ae;
$color-link-water: #e2ecf9;
$color-dodger-blue: #3c67f6;
$color-bahama-blue: #006597;
$color-endeavour: #0052af;
$color-iceberg: #cbe8ef;
$color-blue-ribbon: #06f;
$color-tropical-blue: #c1e6f7;
$color-pattens-blue: #d8ebff;
$color-selago: #f1f4fe;
$color-onahau: #d3f1ff;
$color-catalina-blue: #0a2881;
$color-chambray-blue: #34418f;
$color-lochmara: #0074bf;
$color-cerulean: #0599dc;
$color-crimson: #ea1010;
$color-mandy: #e3496d;
$color-valencia: #d34436;
$color-mojo: #ce5542;
$color-cinnabar: #e74c3c;
$color-cardinal: #d32424;
$color-amaranth: #e7295e;
$color-shiraz: #ab0a1a;
$color-heart-pulse: #ee162a;
$color-torch-red: #f92020;
$color-red: #ff0d00;
$color-red-ribbon: #f1184a;
$color-warning: #ff0000;
$color-vista-white: #faf1f1;
$color-punch: #db3537;
$color-cardinal-red: #d32121;
$color-pomegranate: #f54238;
$color-persian-green: #00b2ad;
$color-elm: #1d7c79;
$color-olive-drab: #4f982b;
$color-green: #00bd04;
$color-celadon: #abe4ac;
$color-cutty-sark: #4d7b72;
$color-forest-green: #3a772b;
$color-blue-romance: #d4f6ce;
$color-mantis: #89c674;
$color-conifer: #78e43e;
$color-sea-green: #2a8652;
$color-persian-green: #009588;
$color-malachite: #00c64f;
$color-line: #06c755;
$color-limeade: #679108;
$color-rice-flower: #ecffe5;
$color-foam: #f6fefa;
$color-frost: #eaf9e5;
$color-gallery: #f0f0f0;
$color-willow-brook: #f3f8f0;
$color-buttercup: #f6a61a;
$color-lightning-yellow: #f9c922;
$color-salmon: #ff8a65;
$color-wheat: #f2d8ab;
$color-irish-coffee: #604526;
$color-yellow-orange: #ffb534;
$color-gorse: #fefe54;
$color-jaffa: #f29f39;
$color-tahiti-gold: #ef850b;
$color-trinidad: #e65000;
$color-selective-yellow: #ffba00;
$color-blaze-orange: #ff6000;
$color-peach-yellow: #f8e3ab;
$color-sandy-beach: #ffebc7;
$color-wasabi: #909526;
$color-sea-buckthorn: #f7ac28;
$color-saffron: #f6b33e;
$color-orange-peel: #f69c00;
$color-casablanca: #f8b43f;
$color-supernova: #fcc400;
$color-ivory: #fffff3;
$color-provincial-pink: #fef8f1;
$color-bon-jour: #e2dfdf;
$color-gray: #8a8a8a;
$color-graphite: #dcdcdc;
$color-dusty-gray: #949494;
$color-nobel: #b5b3b3;
$color-silver-chalice: #a8a5a4;
$color-alabaster: #f7f7f7;
$color-wild-sand: #f5f5f5;
$color-chablis: #fff5f5;
$color-zircon: #f6f8ff;
$color-porcelain: #e7edee;
$color-alto: #cecece;
$color-lavender-blush: #ffecf0;
$color-deep-blush: #e576aa;
$color-cranberry: #e74f95;
$color-persian-pink: #ee72c9;
$color-vivid-violet: #8f3996;
$color-carousel-pink: #fdf3fb;
$color-hot-pink: #ff50bb;
$color-cherub: #fbe2e8;
$color-au-chico: #935d59;
$color-pink-lace: #fed8f6;
$color-wisp-pink: #fef5fb;
$color-whisper: #f7f1f8;
$color-fair-pink: #fee;
$color-pippin: #ffe5e5;
$color-pizazz: #fc8e00;
$silver-chalice: #aaa;
$color-porcelain: #eceeef;
$color-cardinal-pink: #fb565e;
$color-mountain-meadow: #10b495;
$color-white-gray-eee: #eee;
$color-mine_shaft: #373737;
$color-guardsman-red: #be0000;
$color-mint-tulip: #b7eff0;
$color-rose: #fa0087;
$color-pastel-pink: #ffd6e2;
$color-varden: #fff7db;
$color-mango-tango: #de6a00;
$color-pizza: #c79d11;
$color-mine_shaft-2: #2b2b2b;

// new variable for aga
$color-inactive: #ccc;
$primary-color: #998800;
$color-lucky: #cfc392;
$color-yuma: #cfc392;
$color-pampas: #f1efe7;
$color-stiletto: #9a2f2f;
$color-dusty-gray: #999999;

// Global color
$color-primary: #ce5848;
$color-chestnut-rose: #ce5848;
